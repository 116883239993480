<template>
    <div class="wrapper h-[450px] p-5 md:p-20 bg-slate-200">
        <div class="text-white relative top-1/2 space-y-2">
            <div class="font-bold text-4xl md:text-6xl head">Sermons</div>
            <div class="text-xl font-bold uppercase">walking with god daily</div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.wrapper {
    background-image: url('../../assets/images/sermons.png');
    background-size: cover;
    background-position: center;
}
</style>