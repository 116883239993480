<template>
    <div>
        <Hero title="Events"/>

        <div class="px-4 md:px-20 text-xl">
            <p class="mt-5">
                We don’t want you to miss out on all our important events.
                Now that you’re here, explore our upcoming events, mark your calendar, and make sure you’re in attendance.
            </p>
        </div>

        <div v-if="initialDataLoad">
            <div v-if="events.length != 0 || (typeof events != 'string')">
                <div v-if="events.length > 0">
                    <RouterLink :to="`/events/${event.id}`" v-for="(event, index) in events" :key="index" class="flex p-4 m-4 md:mx-44 gap-x-6">
                        <div class="grid">
                            <div class="font-bold">{{ getMonth(new Date(event.data.date).getMonth()) }}</div>
                            <div class="font-bold">{{ new Date(event.data.date).getDate() }}</div>
                        </div>
                        <div class="w-[2px] bg-slate-300"></div>
                        <div>
                            <div class="grid">
                                <div  class="font-bold">{{ event.data.title }}</div>
                                <div>{{ event.data.venue }}</div>
                            </div>
                        </div>
                        <div class="ms-auto">
                            <img :src="event.data.cover_image" :alt="`${event.data.cover_image} thumbnail`" class="h-14 w-14">
                        </div>
                    </RouterLink>
                </div>

                <div v-else>
                    <NothingFound content="No event found" />
                </div>
            </div>
            <div v-else>
                <InternalError :content="events"/>
            </div>
        </div>
        <div v-else>
            <Loader />
        </div>

        <BecomeOurFamily />
        <GetAccquintedTwo class="p-8 md:p-20" />
    </div>
</template>

<script setup>
import Hero from '../components/Events/Hero.vue';
import BecomeOurFamily from '../components/BecomeOurFamily.vue';
import GetAccquintedTwo from '../components/Home/GetAccquintedTwo.vue';
import { useDataStore } from '../stores/useData';
import { storeToRefs } from 'pinia';
import Loader from '../components/Loader.vue';
import NothingFound from '../components/NothingFound.vue'
import InternalError from '../components/InternalError.vue';

let dataStore = useDataStore()

let { initialDataLoad, events } = storeToRefs(dataStore)

function getMonth(index) {
    const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
    ];

    return months[index]
}
</script>

<style scoped>

</style>