<template>
    <div>
        <Hero />
        <Sermon @downloading="downloading"/>
    </div>
</template>

<script setup>
import Hero from '../components/Sermons/Hero.vue';
import Sermon from '../components/Sermons/Sermon.vue';

let emit = defineEmits(['downloading'])

function downloading(data) {
    emit('downloading', data)
}
</script>

<style scoped>

</style>