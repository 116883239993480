<template>
    <div>
        <div class="mouse"></div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.mouse {
	width: 40px;
	height: 70px;
	border: 3px solid white;
	border-radius: 60px;
	position: relative;
	&::before {
		content: '';
		width: 12px;
		height: 12px;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		background-color: white;
		border-radius: 50%;
		opacity: 1;
		animation: wheel 2s infinite;
		-webkit-animation: wheel 2s infinite;
	}
}

@keyframes wheel {
	to {
		opacity: 0;
		top: 50px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 50px;
	}
}
</style>