<template>
    <div class="mt-20 mb-20 md:mb-0">
        <!-- <div class="font-bold text-xl mb-3 text-center md:tect-start">GET ACQUAINTED WITH US</div> -->

        <div class="grid md:grid-cols-3 gap-3">
            <RouterLink to="/our-leadership" class="h-44 our-leadership bg-slate-200">
                <div class="uppercase text-white font-bold text-3xl flex justify-center text-center items-center h-full">Our Leadership</div>
            </RouterLink>

            <RouterLink to="/what-we-believe" class="h-44 what-we-believe bg-slate-200">
                <div class="uppercase text-white font-bold text-3xl flex justify-center text-center items-center h-full">What we believe</div>
            </RouterLink>
            
            <RouterLink to="/sermons" class="h-44 sermons bg-slate-200">
                <div class="uppercase text-white font-bold text-3xl flex justify-center text-center items-center h-full">Sermons</div>
            </RouterLink>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.our-leadership{
    background:  linear-gradient(rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6)), url('../../assets/images/our_leadership.jpg');
    background-size: cover;
}

.what-we-believe{
    background:  linear-gradient(rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6)), url('../../assets/images/what_we_believe.jpg');
    background-size: cover;
}

.sermons {
    background-image: url('../../assets/images/sermons.png');
    background-size: cover;
}
</style>