<template>
    <div>
        <!-- hero video -->
        <div class="video-docker relative h-[80vh] md:h-[85vh] lg:h-screen" style="overflow: hidden;">
            <video src="../../assets/video/TSG-WEBSITE-VIDEO1.mp4" autoplay loop muted playsinline class="absolute h-full scale-[3] md:scale-125 lg:scale-150" ></video>

        <div class="wrapper relative lg:pt-0 text-white grid lg:grid-cols-2 gap-4 bg-slate-200" style="height: auto; min-height: 100%;">
            
            <div class=" hidden lg:block relative" style="z-index: 20;">
                <div class="w-1/2 left-20 bottom-[-0px] absolute">
                    <div class="rounded bg-black p-3 bg-slate-200">
                        <div class="grid grid-cols-2 text-black mb-6">
                            <div class="font-bold">Anouncements</div>
                            <RouterLink to="/events" class="uppercase font-bold flex justify-end">View all</RouterLink>
                        </div>
                        
                        <div class="text-black">
                            <div v-if="initialDataLoad">
                                <div v-if="(typeof events != 'string')">

                                    <div v-if="events.length > 0">
                                        <div class="grid grid-cols-1 divide-y divide-orange-500">
                                            <RouterLink :to="`events/${event.id}`" class=" p-2 rounded text-black" v-for="event in events.slice(0, 2)">
                                                <div class="text-orange-500">{{ event.data.title }}</div>
                                                <div class="text-black text-lg">{{ event.data.description.slice(0, 70) + '...' }}</div>
                                            </RouterLink>
                                        </div>
                                    </div>
                                    <div v-else>
                                        No event found
                                    </div>

                                </div>
                                <div v-else>
                                    {{ events }}
                                </div>

                            </div>
    
                            <div v-else>
                                <Loader />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="lg:relative px-8 flex items-center z-10 mt-10 lg:mt-0">
                <div class="md:absolute px-8 lg:right-20">
                    <div class="uppercase font-thin">From Here to the uttermost</div>
                    <div class="text-4xl md:text-7xl font-bold mt-3 head">Raising proofs of the victorious life in Christ</div>

                    <div class="flex gap-2 mt-5">
                        <RouterLink to="/sermons" class="max-w-fit px-3 lg:px-6 py-3 border-2 border-orange-500 rounded hover:cursor-pointer">Sermons</RouterLink>
                        <RouterLink to="/listen-online" class="max-w-fit px-3 lg:px-6 py-3 border-2 border-orange-500 rounded hover:cursor-pointer">Stream Mixlr</RouterLink>
                    </div>

                    <div class="mt-5 font-semibold">
                        Join us for service on; <br>
                        Sunday from 8am <br>
                        Wednesday from 5:30pm (GMT +1)
                    </div>
                </div>
            </div>

            <div class="hidden lg:flex absolute bottom-5 left-1/2 z-10">
                <ScrollDownMouse />
            </div>
        </div>
    </div>

    <!-- sm aanouncements -->
    <div class="block lg:hidden mt-5 px-8">
        <div>
            <div v-if="initialDataLoad" class="">
                <div v-if="(typeof events != 'string')">
                
                    <div v-if="events.length > 0">
                        <div class="grid grid-cols-2 text-black mb-6">
                            <div class="font-bold">Anouncements</div>
                            <RouterLink to="/events" class="uppercase font-bold flex justify-end">View all</RouterLink>
                        </div>
                    </div>

                    <div v-if="events.length > 0" class="flex gap-x-5 overflow-auto">
                        <RouterLink :to="`events/${event.id}`" class="flex-none" v-for="event in events.slice(0, 5)">
                            <img :src="event.data.cover_image" :alt="`${event.data.title} thumbnail`" class=" h-40 w-48 rounded">
                        </RouterLink>
                    </div>

                </div>
                <div v-else>
                    {{ events }}
                </div>
            </div>

            <div v-else>
                <Loader />
            </div>
        </div>
    </div>
    </div>
</template>

<script setup>
import { useDataStore } from '../../stores/useData';
import { storeToRefs } from 'pinia'
import Loader from '@/components/Loader.vue'
import ScrollDownMouse from '../ScrollDownMouse.vue';
import { ref } from 'vue'

let dataStore = useDataStore()

let { initialDataLoad, events } = storeToRefs(dataStore)

</script>

<style scoped>
.wrapper {
    background: gray;
}

video {
    z-index: 10;
    width: 100%;
    /* transform: scale(1.1); */
}

.video-docker::after {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

@media (max-width: 315px) {
    video {
        transform: scale(4);
        height: 100;
    }
}

@media (min-width: 750px) and (max-width: 800px) {
    video {
        transform: scale(1.5);
        height: 100;
    }
}

</style>