<template>
    <div>
        <Hero title="Event" />

        <div v-if="event">
            <div v-if="(typeof event != 'string')" class="grid md:grid-cols-5 py-10 px-8 md:px-20 lg:px-40">
                <div class="grid col-span-3 gap-y-3">
                    <div class="font-bold text-4xl">
                        {{ event[0].data.title }}
                    </div>
    
                    <div class="text-lg lg:text-xl">
                        {{ event[0].data.description }}
                    </div>
    
                    <div>
                        <img :src="event[0].data.cover_image" :alt="`${event[0].data.description} thumbail`" class="lg:h-[550px]">
                    </div>
    
                    <div class="mt-5" v-if="event[0].data.what_to_know">
                        <div class="font-bold text-xl">What to know</div>
    
                        <div class="text-lg lg:text-xl">
                            {{ event[0].data.what_to_know }}
                        </div>
                    </div>
                </div>
    
                <div class="col-span-2">
                    <div class="grid py-10 lg:py-0 md:px-5 gap-3">
                        <div v-if="event[0].data.date">
                            <div class="font-bold text-lg">Date of event</div>
                            <div class="text-lg">{{ new Date(event[0].data.date).toDateString() }}</div>
                        </div>
                        <div v-if="event[0].data.time">
                            <div class="font-bold text-lg">Time</div>
                            <div class="text-lg">{{ event[0].data.time }}</div>
                        </div>
                        <div v-if="event[0].data.venue">
                            <div class="font-bold text-lg">Address</div>
                            <div class="text-lg">{{ event[0].data.venue }}</div>
                        </div>
                        <div v-if="event[0].data.contact">
                            <div class="font-bold text-lg">Contact</div>
                            <div class="text-lg">{{ event[0].data.contact }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div v-if="event.startsWith('An error occured')">
                    <InternalError :content="event" />
                </div>
                <div v-else>
                    <NothingFound content="Event not found. Check the link and try again." />
                </div>
            </div>
        </div>

        <div v-else>
            <Loader />
        </div>

        <BecomeOurFamily />
        <GetAccquintedTwo class="p-8 lg:p-20" />
    </div>
</template>

<script setup>
import Hero from '../components/Events/Hero.vue';
import BecomeOurFamily from '../components/BecomeOurFamily.vue';
import GetAccquintedTwo from '../components/Home/GetAccquintedTwo.vue';
import { useRoute } from 'vue-router';
import Loader from '../components/Loader.vue';
import { ref } from 'vue';
import firebaseFuncs from '../utils/firebase';
import InternalError from '../components/InternalError.vue'
import NothingFound from '../components/NothingFound.vue'

let { getSingleData } = firebaseFuncs

let docId = useRoute().params.id
let event = ref(null)

async function getDataOnLoad() {
    event.value = await getSingleData('events', docId)
}
getDataOnLoad()

</script>

<style scoped>

</style>