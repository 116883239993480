<template>
    <div>
        <div class="w-full px-4 py-3 text-sm border-2 border-slate-700 rounded uppercase my-1">
            <div class="grid grid-cols-3">
                <div class="col-span-2">
                    {{ track.track }} <br>
                    <small class="text-slate-500 text-xs">{{ (track.size / 1000000).toFixed(2) + ' MB' }}</small>
                </div>
                <div class="flex justify-center items-center gap-x-7 ">
                    <div @click="playTrack" v-if="!audioPlayed" class="hover:cursor-pointer">
                        <i class="fa-solid fa-play"></i>
                    </div>
                    <div @click="pauseTrack" v-if="audioPlayed" class="hover:cursor-pointer">
                        <i class="fa-solid fa-pause"></i>
                    </div>
                    <div @click="downloadAudio(track)" class="hover:cursor-pointer p-3">
                        <i class="fa-solid fa-download"></i>
                    </div>
                </div>

                <audio ref="audioElement" :src="track.audio_file"></audio>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

let emits = defineEmits(['downloading'])
defineProps(['track'])

let audioPlayed = ref(false)
const audioElement = ref(null);

function playTrack() {
    audioPlayed.value = true
    audioElement.value.play()
}
function pauseTrack() {
    audioPlayed.value = false
    audioElement.value.pause()
}

async function downloadAudio(audioURL) {
  // Fetch the audio data
  fetch(audioURL.audio_file)
  .then(response => {
      const totalSize = response.headers.get('content-length');
      let downloadedSize = 0;

      const readableStream = response.body;

      // Create a new ReadableStream and use the reader to read chunks
      const reader = readableStream.getReader();

      return new ReadableStream({
        start(controller) {
          function read() {
            return reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }

              downloadedSize += value.byteLength;
              const progress = (downloadedSize / totalSize) * 100;

            //   updateProgress(audioURL.track, progress, false);
              emits('downloading', { name: audioURL.track, progress })

              controller.enqueue(value);
              return read();
            });
          }

          return read();
        }
      });
    })
    .then(stream => new Response(stream))
    .then(response => response.blob())
    .then(blob => {
      const blobURL = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobURL;
      link.download = audioURL.track + '.mp3';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(blobURL);
    })
    .catch(error => {
        emits('downloading', { name: audioURL.track, progress: 'failed' })
    });
}
</script>

<style scoped>

</style>