<template>
    <div class="p-8 lg:p-20">

        <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <RouterLink :to="`/what-we-believe`" class="h-44 bg-slate-200 relative what-we-believe">
                <div class="uppercase text-white font-bold text-3xl text-center flex justify-center items-center h-full relative">what we believe</div>
            </RouterLink>

            <RouterLink :to="`/who-we-are`" class="h-44 bg-slate-200 relative who-we-are">
                <div class="uppercase text-white font-bold text-3xl text-center flex justify-center items-center h-full">who we are</div>
            </RouterLink>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>
.what-we-believe {
    background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)), url('../../assets/images/what_we_believe.jpg');
    background-position: center;
    background-size: cover;
}

.who-we-are {
    background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)), url('../../assets/images/brethren_hero.png');
    background-position: center;
    background-size: cover;
}
</style>