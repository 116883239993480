<script setup>
import { RouterLink, RouterView } from 'vue-router'
import Navbar from '../src/components/Navbar.vue';
import Footer from './components/Footer.vue';
import { initFlowbite } from 'flowbite'
import { onMounted, ref } from 'vue'
import { useDataStore } from './stores/useData';
import { storeToRefs } from 'pinia'
import DownloadToast from '@/components/DownloadToast.vue'

let dataStore = useDataStore()

let { getAllData } = dataStore
let { initialDataLoad } = storeToRefs(dataStore)


onMounted(() => {
    initFlowbite();
})

async function getDataOnLoad() {
    if (!initialDataLoad.value) {
        await getAllData()
    }
}
getDataOnLoad()

let isDownloading = ref([])

function downloading(data) {
  const existingDownload = isDownloading.value.find((download) => download.name === data.name);

  if (existingDownload) {
    existingDownload.progress = data.progress;
  } else {
    isDownloading.value.push(data);
  }
}
</script>

<template>
  <Navbar />
  <RouterView @downloading="downloading" class="overflow-hidden"/>

  <div class="fixed bottom-0 m-2 right-0 p-4 text-white">
      <div v-if="isDownloading.length > 0">
          <div v-for="(downlaod, index) in isDownloading" :key="index" class="grid">
              <DownloadToast :data="downlaod" v-if="downlaod.progress != 100" @closeToast="isDownloading.splice(index, 1)" class="mt-2"/>
          </div>
      </div>
  </div>

  <Footer />
</template>

<style>
:root{
  --tsg-orange: #f97316
}

*{
  font-family: 'Archivo', sans-serif;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.head {
    letter-spacing: -2px;
}
</style>
