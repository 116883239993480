<template>
    <div class="py-5">
        <div class="font-bold text-xl mb-3">GET ACQUAINTED WITH US</div>

        <div class="grid md:grid-cols-3 gap-3">
            <RouterLink to="/devotional" class="h-44 devotional bg-slate-200">
                <div class="uppercase text-white font-bold text-3xl flex justify-center items-center h-full">Devotional</div>
            </RouterLink>

            <RouterLink to="/give" class="h-44 give bg-slate-200">
                <div class="uppercase text-white font-bold text-3xl flex justify-center items-center h-full">Give</div>
            </RouterLink>

            <RouterLink to="/who-we-are" class="h-44 who-we-are bg-slate-200">
                <div class="uppercase text-white font-bold text-3xl flex justify-center items-center h-full">Who we are</div>
            </RouterLink>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.devotional{
    background-image: url('../../assets/images/devotional.png');
    background-size: cover;
}

.give{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/images/give.jpg');
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.1)
}

.who-we-are {
    background-image: url('../../assets/images/brethren_image.png');
    background-size: cover;
}
</style>