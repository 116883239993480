<template>
    <div class="bg-[#111315] px-5 lg:px-20">
        <div class="grid md:grid-cols-7 pt-12 pb-5 gap-y-5">
            <div class="col-span-2 text-white">
                <img src="../assets/images/tsg_logo_2.png" alt="church_logo">

                <div class="mt-[21px]">We have taken on that Global Mandate of Jesus Christ to preach the gospel, disciple men and demonstrate the power of God.</div>
            </div>

            <div class="hidden md:block"></div>

            <div>
                <div class="text-orange-500 font-bold">Quick links</div>
                <div class="text-slate-400 space-y-2 mt-3">
                    <div><RouterLink to="/listen-online">LISTEN ONLINE</RouterLink></div>
                    <div><RouterLink to="/sermons">SERMONS</RouterLink></div>
                    <div><RouterLink to="/events">EVENTS</RouterLink></div>
                    <div><RouterLink to="/devotional">DEVOTIONAL</RouterLink></div>
                </div>
            </div>

            <div>
            </div>

            <div class="col-span-2">
                <div class="text-orange-500 font-bold">Contact</div>
                <div class="text-slate-400 space-y-2 mt-3">
                    <div><i class="fa-solid fa-phone-volume"></i> +2348103342520, +2349039383664, +2348160522267</div>
                    <div><i class="fa-regular fa-envelope"></i> info@thesaintsgathering.org</div>
                </div>
            </div>

        </div>

        <div class="bg-white w-full h-[1px]"></div>
        <div class="grid grid-cols-2 py-4 text-white">
            <div class="flex gap-2">
                <a href="https://www.facebook.com/people/The-Saints-Gathering-Church/100069079146538/" target="_blank"><i class="fa-brands fa-facebook"></i></a>
                <a href="https://twitter.com/tsg_church" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
                <a href="https://www.instagram.com/thesaintsgatheringchurch/" target="_blank"><i class="fa-brands fa-instagram"></i></a>
            </div>

            <div class="flex justify-end text-slate-400">
                © {{ new Date().getFullYear() }} The Saints Gathering Church. All rights reserved.
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>

</style>