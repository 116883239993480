import { defineStore } from 'pinia'
import firebaseFuncs from '@/utils/firebase'

let { getData } = firebaseFuncs

export const useDataStore = defineStore('useData', {
  state: () => ({
    sermons: null,
    devotionals: null,
    events: null,

    initialDataLoad: false
  }),

  actions: {
    async getAllData () {
      this.events = await getData('events')
      this.sermons = await getData('series')
      this.devotionals = await getData('devotionals')

      this.initialDataLoad = true
    },

    async getSermons () {
      this.sermons = await getData('series')

      return this.sermons
    },

    async getDevotionals () {
      this.devotionals = await getData('devotionals')
    },

    async getEvents () {
      this.events = await getData('events')
    }
  }
})
