<template>
    <div class="p-5 md:p-20" v-if="sermon">
        <div v-if="(typeof sermon != 'string')">
            <div class="p-3 bg-[#F5F2F0] font-bold text-[#111315] uppercase">
                {{ !date ? new Date(sermon[0].data.date_created).getFullYear() : new Date(sermon[0].data.date).getFullYear() }} MESSAGES <i class="fa-solid fa-greater-than px-5"></i> {{ sermon[0].data.title }}
            </div>
    
            <div class="mt-5">
                <div class="uppercase font-bold text-xl md:text-3xl text-[#111315] my-5">
                    {{ sermon[0].data.title }}
                    <div class="bg-orange-500 h-[2px] w-52 mt-2"></div>
                </div>
    
                <div class="grid grid-cols-1 md:grid-cols-3 gap-y-6">
                    <div class="col-span-2 text-lg">
                        <img :src="sermon[0].data.cover_image" :alt="`${sermon[0].data.title} thumbnial`" class="sermon-cover w-full bg-slate-200">
    
                        <div class="mt-2">
                            Date uploaded: {{ new Date(sermon[0].data.date_created).toDateString() }}
                        </div>
                        <div>
                            {{ sermon[0].data.description }}
                        </div>
                    </div>
    
                    <div class="text-lg md:ps-6">
                        <div>
                            <div class="grid grid-cols-6">
                                <div class="col-span-5">
                                    Click on the track to play online or download
                                </div>
    
                                <div class="text-end">
                                    <div class="hover:cursor-pointer" @click="copyToClipboard"><i class="fa-regular fa-copy"></i></div>
                                    <div class="hover:cursor-pointer" @click="shareSermon"><i class="fa-solid fa-share-nodes"></i></div>
                                </div>
                            </div>
    
                            <div class="mt-3">
                                <TrackButton v-for="track in sermon[0].data.sermons" :track="track" @downloading="downloading"/>
                            </div>
                        </div>
                    </div>
    
                </div>
    
                <div>
                    <RandUploads :sermons="randSermons" header="other uploads in this collection" />
                </div>
                

                <FeedbackToast 
                    v-if="feedback"
                    :feedback="feedback"
                    @closeToast="feedback = null"
                />
            </div>
        </div>

        <div v-else>
            <div v-if="sermon.startsWith('An error occured')">
                <InternalError :content="sermon" />
            </div>
            <div v-else>
                <NothingFound content="Sermon not found. Check the link and try again." />

                <div>
                    <RandUploads :sermons="randSermons" header="other uploads in this collection" />
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <Loader />
    </div>
</template>

<script setup>
import TrackButton from '../TrackButton.vue';
import RandUploads from './RandUploads.vue';
import firebaseFuncs from '../../utils/firebase';
import { useRoute } from 'vue-router';
import Loader from '../Loader.vue';
import {ref, watch } from 'vue'
import NothingFound from '@/components/NothingFound.vue'
import InternalError from '@/components/InternalError.vue'
import { storeToRefs } from 'pinia';
import {useDataStore} from '@/stores/useData.js'
import FeedbackToast from '@/components/FeedbackToast.vue'
import { onBeforeRouteUpdate } from 'vue-router'

const currentURL = window.location.href;

let emit = defineEmits(['downloading'])
let dataStore = useDataStore()

let { getSingleData } = firebaseFuncs
let { sermons, initialDataLoad } = storeToRefs(dataStore)

let feedback = ref(null)

let docId = useRoute().params.id
let sermon = ref(null)

let temp 
if (initialDataLoad.value) {
    temp = [...sermons.value]
}

let randSermons = ref(null)

async function getDataOnLoad() {
    sermon.value = await getSingleData('series', docId)
}
getDataOnLoad()

watch(sermons, (newSermons) => {
    temp = [...newSermons]

    randSermons.value = getRandomSubsetFromsermons(4)
})

function getRandomSubsetFromsermons(subsetSize) {
    if (temp) {
        if (temp.length <= subsetSize) {
        return temp.slice();
    }

    // Fisher-Yates shuffle
    for (let i = temp.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [temp[i], temp[j]] = [temp[j], temp[i]];
    }

    return temp.slice(0, subsetSize);
    }
}
randSermons.value = getRandomSubsetFromsermons(4)


function shareSermon() {
    feedback.value = null

    if (navigator.share) {
        navigator.share({
        title: sermon.value[0].data.title,
        url: currentURL
    }).then(() => {
        feedback.value = { status: 'ok', message: 'Thanks for sharing' }
    })
    .catch(error => {
        feedback.value = { status: 'failed', message: `Cannot share. ${error}` }
    });
  } else {
    feedback.value = { status: 'failed', message: `Cannot share.` }
  }
}

function copyToClipboard() {
    feedback.value = null
    navigator.clipboard.writeText(currentURL)
        .then(() => {
            feedback.value = { status: 'ok', message: 'Sermon link copied to clipboard.' }
        })
        .catch((err) => {
            feedback.value = { status: 'failed', message: 'Could not copy sermon link' + err }
        });
}

function downloading(data) {
  emit('downloading', data)
}

onBeforeRouteUpdate(async (to, from) => {
  if (to.name == 'sermon') {
    sermon.value = null
    sermon.value = await getSingleData('series', to.params.id)
    randSermons.value = getRandomSubsetFromsermons(4)
  }
});
</script>

<style scoped>
.sermon-cover{
    background-size: cover;
    background-position: center;
}
</style>