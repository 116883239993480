import { initializeApp } from 'firebase/app'
import { getFirestore, collection, query, onSnapshot, orderBy, getDocs, getDoc, doc } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyDacU8HGVpQrKQJzgqFTeuwG6viniPFofY",
    authDomain: "tsgmedia-e0472.firebaseapp.com",
    databaseURL: "https://tsgmedia-e0472.firebaseio.com",
    projectId: "tsgmedia-e0472",
    storageBucket: "tsgmedia-e0472.appspot.com",
    messagingSenderId: "393265654100",
    appId: "1:393265654100:web:33522d7bce719d9eb200c1",
    measurementId: "G-N293YFPT0L"
};

initializeApp(firebaseConfig)

let db = getFirestore()

async function getData(dbcollection) {
    let data = []

    try {
        if (!dbcollection || typeof dbcollection != 'string') return 'Something went wrong, invalid or no param'

        let colRef = collection(db, dbcollection)
        let q = query(colRef, orderBy('date_created', 'desc'))
        
        let docs = await getDocs(q)

        docs.docs.forEach(doc => {
            data.push({ id: doc.data().docId, data: doc.data() })
        })

        /**
         * onSnapshot(q, (res) => {
            console.log(res.docs)
            res.docs.forEach(doc => {
                data.push({ id: doc.data().docId, data: doc.data() })
            })
        })
         */

        return data
    } catch (error) {
        return 'An error occured: ' + error
    }
}

async function getSingleData(collection, docId) {
    let document

    try {
        if (!collection || typeof collection != 'string') return 'Something went wrong, invalid or no param'
        if (!docId) return 'Something went wrong, invalid or no param'

        let docRef = doc(db, collection, docId)

        let req = await getDoc(docRef)
        
        document = [{ id: req.data().docId, data: req.data() }]

        return document

    } catch (error) {
        if (error.message.includes("Cannot read properties of undefined (reading 'docId')")) {
            return 'Something went wrong, invalid or no param'
        } else {
            return 'An error occured: ' + error.message
        }
    }
}

export default {
    getData,
    getSingleData
}