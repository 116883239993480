<template>
    <div class="my-10">
        <!-- search bar -->
        <label class="relative block">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <i class="fa-solid fa-magnifying-glass"></i>
            </span>
            <input 
                v-model="searchBarVal"
                class="w-full bg-white placeholder:font-italitc border border-slate-300 rounded-full py-2 pl-10 pr-4 focus:outline-none"
                placeholder="Search sermon" type="text" 
            />
        </label>

        <!-- years -->
        <div v-if="!isLoading">
            <div>
                <div class="flex gap-x-10 overflow-auto">
                    <div class="font-bold p-3 text-center text-xl hover:cursor-pointer"
                        :class="{'text-orange-500': selectedYear == year, 'text-black': selectedYear != year }"
                        v-for="year in sermonYears" @click="sortSermonByYear(year)">
                        {{ year }} {{ year == 'All' ? '' : 'Messages'}}
                    </div>
                </div>
            </div>
    
            <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-12">
                <div v-for="sermon in sermonsArr">
                    <RouterLink :to="`/sermons/${sermon.id}`" class="h-40 bg-red-200 section-image bg-slate-200">
                        <img :src="sermon.data.cover_image" class="w-full " :alt="sermon.data.title + ' image'" loading="lazy">
                        <div class="hidden md:block">
                            <div class="text-lg font-bold">{{ sermon.data.title }}</div>
                            <div class="text-sm text-[#f97316]">
                                <span class="font-bold">Upload date:</span> 
                                {{ new Date(sermon.data.date_created).toDateString() }}
                            </div>
                        </div>
                    </RouterLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useDataStore } from '../../stores/useData';
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router';

let dataStore  = useDataStore()

let { getSermons } = dataStore
let { sermons, initialDataLoad } = storeToRefs(dataStore)

let router = useRouter()

let sermonYears = ref(['All'])
let selectedYear = ref('All')
let temp = ref(sermons)
let sermonsArr = ref(null)
let isLoading = ref(true)
let searchBarVal = ref()

async function getDataOnLoad() {
    if (!initialDataLoad.value) {
        await getSermons()
    }
    sermonsArr.value = sermons.value

    // get years of sermons
    if (sermonsArr.value && typeof sermonsArr.value == 'object') {
        sermonsArr.value.forEach(sermon => {
            let date = new Date(sermon.data.date_created)
            let year = date.getFullYear()

            if (!sermonYears.value.includes(year)) {
                sermonYears.value.push(year)
            }
        });
    }

    isLoading.value = false
}
getDataOnLoad()

function sortSermonByYear(year) {
    let filteredSermons;
    if (year == 'All') {
        filteredSermons = [...temp.value];
        selectedYear.value = year;
    } else {
        filteredSermons = [...temp.value].filter(sermon => {
            let date = new Date(sermon.data.date_created);
            return date.getFullYear() == year;
        });
        selectedYear.value = year;
    }
    sermonsArr.value = filteredSermons;
}

watch(searchBarVal, (newSearchBarVal) => {
    router.push(`/sermons?q=${searchBarVal.value}`)
})
</script>

<style scoped>
.section-image {
    background-image: url('../../assets/images/placeholder.jpg');
    background-size: contain;
    background-position: center;
}
</style>