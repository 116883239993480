<template>
    <div>
        <div class="flex font-bold text-lg uppercase justify-center">Other devotionals</div>

        <div class="mt-5">
            <div class="flex gap-x-5 overflow-auto">
                <div
                 :class="{'text-orange-500 underline underline-offset-4': selectedYear == year, 'text-black': selectedYear != year }"
                 class="font-bold hover:cursor-pointer" v-for="year in devotionalYears" @click="sortDevotionalsByYear(year)">
                    {{ year }}
                </div>
            </div>

            <div class="grid md:grid-cols-2 lg:grid-cols-4 mt-5 gap-5">
                <div class="bg-slate-100 p-5" v-for="devotional in devotionalArr.slice(0, 4)">
                    <img :src="devotional.data.cover_image" class="w-full" :alt="`${devotional.data.month} ${devotional.data.year} devotional thumbnail`">

                    <div class="grid grid-cols-2 mt-3">
                        <div>
                            <div class="font-bold text-xl">{{ devotional.data.month }}</div>
                            <div>{{ devotional.data.year }}</div>
                            <div class="text-slate-500 text-xs"> {{(devotional.data.size / 1000000).toFixed(2) + ' MB'}} </div>
                        </div>
                        <div class="flex justify-end ">
                            <div @click="$emit('download', devotional.data)" class="p-4 h-fit rounded-full bg-orange-500 grid content-center hover:cursor-pointer">
                                <i class="fa-solid fa-download"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
             v-if="devotionalArr.length > 5"
             @click="tog"
             class="flex justify-center text-xl mt-5 items-center hover:cursor-pointer">
                <PrimaryButton class="text-sm" :content="viewMore ? 'View less' : 'View more'" />
            </div>

            <div v-if="viewMore" class="grid md:grid-cols-2 lg:grid-cols-4 mt-5 gap-5">                
                <div class="bg-slate-100 p-5" v-for="devotional in devotionalArr.slice(4)">
                    <img :src="devotional.data.cover_image" class="w-full" :alt="`${devotional.data.month} ${devotional.data.year} devotional thumbnail`">

                    <div class="grid grid-cols-2 mt-3">
                        <div>
                            <div class="font-bold text-xl">{{ devotional.data.month }}</div>
                            <div>{{ devotional.data.year }}</div>
                            <div class="text-slate-500 text-xs"> {{(devotional.data.size / 1000000).toFixed(2) + ' MB'}} </div>
                        </div>
                        <div class="flex justify-end ">
                            <div @click="$emit('download', devotional.data)" class="p-4 h-fit rounded-full bg-orange-500 grid content-center">
                                <i class="fa-solid fa-download"></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useDataStore } from '../../stores/useData';
import { storeToRefs } from 'pinia';
import PrimaryButton from '../PrimaryButton.vue';

let dataStore = useDataStore()
let { devotionals, initialDataLoad } = storeToRefs(dataStore) 
let { getDevotionals } = dataStore
let viewMore = ref(false)

function tog() {
    viewMore.value = !viewMore.value
}

let devotionalYears = ref(['All'])
let isLoading = ref(true)
let devotionalArr = ref(null)

async function getDataOnLoad() {
    if (!initialDataLoad.value) {
        await getDevotionals()
    }

    devotionalArr.value = devotionals.value

    // get years of devotioanls
    if (devotionalArr.value && typeof devotionalArr.value == 'object') {
        devotionalArr.value.forEach(devotional => {
            let year = devotional.data.year

            if (!devotionalYears.value.includes(year)) {
                devotionalYears.value.push(year)
            }
        });
    }

    isLoading.value = false
}
getDataOnLoad()

let temp = ref(devotionals)
let selectedYear = ref('All')

function sortDevotionalsByYear(year) {
    let filteredDevotionals;

    if (year == 'All') {
        filteredDevotionals = temp.value;
        selectedYear.value = year;
    } else {
        filteredDevotionals = temp.value.filter(devotional => {
            let devYear = devotional.data.year;
            return devYear == year;
        });
        selectedYear.value = year;
        console.log(filteredDevotionals)
    }

    devotionalArr.value = filteredDevotionals;
}
</script>

<style scoped>

</style>