<template>
    <div class="p-8 lg:p-20">

        <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <RouterLink :to="`/our-ministries/bible-club`" class="h-44 bg-slate-200 relative bible-club">
                <div class="uppercase text-white font-bold text-3xl text-center flex justify-center items-center h-full relative">bible club</div>
            </RouterLink>

            <RouterLink :to="`/our-ministries/school-outreach`" class="h-44 bg-slate-200 relative school-outreach">
                <div class="uppercase text-white font-bold text-3xl text-center flex justify-center items-center h-full">school outreach</div>
            </RouterLink>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>
.bible-club {
    background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)), url('../../assets/images/bible_club_cover.jpg');
    background-position: center;
    background-size: cover;
}

.school-outreach {
    background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)), url('../../assets/images/bible_club.jpg');
    background-position: center;
    background-size: cover;
}
</style>