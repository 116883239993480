<template>
    <div v-if="sermons" class="py-10">
        <label class="relative block mb-5">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <i class="fa-solid fa-magnifying-glass"></i>
            </span>
            <input v-model="searchBarVal" ref="input"
                class="w-full bg-white placeholder:font-italitc border border-slate-300 rounded-full py-2 pl-10 pr-4 focus:outline-none"
                placeholder="Search" type="text" />
        </label>

        <RouterLink to="/sermons" >
            <SecondaryButton @click="$emit('clearSearchQuery')" content="Clear search" class="mb-4 w-fit" />
        </RouterLink>

        <div class="font-bold mb-4">Search results for "{{searchQuery}}"</div>

        <div v-if="sermons.length > 0" class="grid grid-cols-2 lg:grid-cols-4 gap-5">
            <div v-for="sermon in sermons">
                <RouterLink :to="`/sermons/${sermon.id}`" class="h-40 bg-red-200 section-image bg-slate-200">
                    <img :src="sermon.data.cover_image" class="h-24 lg:h-40 w-full" :alt="sermon.data.title + ' image'" loading="lazy">
                    <div class="hidden md:block">
                        <div class="text-lg font-bold">{{ sermon.data.title }}</div>
                        <div class="text-sm text-[#f97316]"><span class="font-bold">Upload date:</span> {{ new
                            Date(sermon.data.date_created).toDateString() }}</div>
                    </div>
                </RouterLink>
            </div>
        </div>
        <div v-else>
            <NothingFound :content='`No sermon with "${searchQuery}" found`' />
        </div>
    </div>
    <div v-else>
        <Loader />
    </div>
</template>

<script setup>
import NothingFound from '../NothingFound.vue';
import Loader from '../Loader.vue';
import SecondaryButton from '../SecondaryButton.vue';
import { onMounted, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

let props = defineProps(['searchQuery', 'sermons'])

let router = useRouter()
let route = useRoute()

let searchBarVal = ref(route.query.q)
let input = ref(null)

watch(searchBarVal, (newSearchBarVal) => {
    router.push(`/sermons?q=${searchBarVal.value}`)
})

setTimeout(() => {
    searchBarVal.value = route.query.q
}, 50)

if (props.sermons) {
    
}


onMounted(() => {
    if (props.sermons) {
        input.value.focus()
    }
})
</script>

<style scoped>
.section-image{
    background-image: url('../../assets/images/placeholder.jpg');
    background-size: contain;
    background-position: center;
}
</style>