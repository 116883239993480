<template>
    <div class="flex justify-center my-10">
        <div class="loader"></div>
    </div>
</template>

<style scoped>
.loader {
    width: 25px;
    aspect-ratio: 1;
    background: #000000;
    box-shadow: 30px 0 var(--tsg-orange);
    animation:
        l4-1 1s infinite alternate,
        l4-2 .5s infinite alternate;
}

@keyframes l4-1 {

    0%,
    40% {
        transform: rotate(0)
    }

    80%,
    100% {
        transform: rotate(.5turn)
    }
}

@keyframes l4-2 {

    80%,
    100% {
        box-shadow: 16px 0 var(--tsg-orange)
    }
}</style>