import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SermonView from '../views/SermonView.vue'
import SermonsView from '../views/SermonsView.vue'
import WhoWeAreView from '../views/WhoWeAreView.vue'
import WhatWeBelieveView from '../views/WhatWeBelieveView.vue'
import OurLeadershipView from '../views/OurLeadershipView.vue'
import EventsView from '../views/EventsView.vue'
import EventView from '../views/EventView.vue'
import ContactView from '../views/ContactView.vue'
import BibleClubView from '../views/BibleClubView.vue'
import MissionsView from '../views/MissionsView.vue'
import SchoolOutreachView from '../views/SchoolOutreachView.vue'
import GiveView from '../views/GiveView.vue'
import DevotionalView from '../views/DevotionalView.vue'
import ListenOnlineView from '../views/ListenOnlineView.vue'
import BookView from '../views/BookView.vue'
import NotFoundView from '../views/NotFoundView.vue'

let routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home - The Saints Gathering Church'
    }
  },
  {
    path: '/sermons',
    name: 'sermons',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: SermonsView,
    meta: {
      title: 'Sermons - The Saints Gathering Church'
    }
  },
  {
    path: '/sermons/:id',
    name: 'sermon',
    component: SermonView,
    meta: {
      title: 'Sermon - The Saints Gathering Church'
    }
  },
  {
    path: '/who-we-are',
    name: 'who-we-are',
    component: WhoWeAreView,
    meta: {
      title: 'Who we are - The Saints Gathering Church'
    }
  },
  {
    path: '/what-we-believe',
    name: 'what-we-believe',
    component: WhatWeBelieveView,
    meta: {
      title: 'What we believe - The Saints Gathering Church'
    }
  },
  {
    path: '/our-leadership',
    name: 'our-leadership',
    component: OurLeadershipView,
    meta: {
      title: 'Our leadership - The Saints Gathering Church'
    }
  },
  {
    path: '/events',
    name: 'events',
    component: EventsView,
    meta: {
      title: 'Events - The Saints Gathering Church'
    }
  },
  {
    path: '/events/:id',
    name: 'event',
    component: EventView,
    meta: {
      title: 'Event - The Saints Gathering Church'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      title: 'Contact - The Saints Gathering Church'
    }
  },
  {
    path: '/our-ministries/bible-club',
    name: 'bible-club',
    component: BibleClubView,
    meta: {
      title: 'Bible club - The Saints Gathering Church'
    }
  },
  {
    path: '/our-ministries/missions',
    name: 'missions',
    component: MissionsView,
    meta: {
      title: 'Missions - The Saints Gathering Church'
    }
  },
  {
    path: '/our-ministries/school-outreach',
    name: 'school outreach',
    component: SchoolOutreachView,
    meta: {
      title: 'School outreach - The Saints Gathering Church'
    }
  },
  {
    path: '/give',
    name: 'give',
    component: GiveView,
    meta: {
      title: 'Give - The Saints Gathering Church'
    }
  },
  {
    path: '/devotional',
    name: 'devotional',
    component: DevotionalView,
    meta: {
      title: 'Devotionals - The Saints Gathering Church'
    }
  },
  {
    path: '/listen-online',
    name: 'listen-online',
    component: ListenOnlineView,
    meta: {
      title: 'Listen online - The Saints Gathering Church'
    }
  },
  {
    path: '/book',
    name: 'book',
    component: BookView,
    meta: {
      title: 'Book - The Saints Gathering Church'
    }
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    component: NotFoundView,
    meta: {
      title: '404 - The Saints Gathering Church'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior(to, from, savedPosition) {
    return { top: 0, behavior: 'smooth' };
  }
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`
  next()
})

export default router
