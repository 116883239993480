<template>
    <div class="relative">
        <header class="text-gray-600 body-font fixed w-full z-30 md:px-20 px-4" ref="navbar">
            <div class="grid grid-cols-2 lg:grid-cols-12 mx-auto md:py-5 py-3 flex-col md:flex-row items-center">
                <RouterLink to="/" class="lg:col-span-2 title-font font-medium text-gray-900 mb-4 md:mb-0">
                    <img src="../assets/images/tsg_logo.png" alt="church_logo" class="inline-flex items-center" />
                </RouterLink>

                <div class="relative inline-flex items-center justify-end block lg:hidden">
                    <!-- Mobile menu button -->
                    <button class="rounded-md p-1 text-gray-400 border" data-drawer-target="drawer-right"
                        data-drawer-show="drawer-right" data-drawer-placement="right"
                        aria-controls="drawer-right">
                        <span class="sr-only">Open main menu</span>

                        <div class="flex ">
                            <i class="fa-solid fa-bars h-6 w-6"></i>
                        </div>
                    </button>
                </div>

                <nav class="lg:col-span-10 flex justify-end text-white lg:gap-x-2 h-full lg:flex items-start hidden">

                    <div @mouseleave="showGetToKnowUsDropDown = false" @mouseenter="showGetToKnowUsDropDown = true">
                        <div class="hover:cursor-pointer">
                            Get to know us
                            <i class="fa-solid fa-chevron-down"></i>
                        </div>
                        <div v-if="showGetToKnowUsDropDown" class="absolute z-20 w-56  focus:outline-none" role="menu"
                            aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                            <div class="mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                <div class="py-1" role="none">
                                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                                    <RouterLink to="/what-we-believe" class="text-gray-700 block px-4 py-2 text-sm"
                                        role="menuitem" tabindex="-1" id="menu-item-0">What we believe</RouterLink>
                                    <RouterLink to="/who-we-are" class="text-gray-700 block px-4 py-2 text-sm"
                                        role="menuitem" tabindex="-1" id="menu-item-1">Who we are</RouterLink>
                                    <RouterLink to="/our-leadership" class="text-gray-700 block px-4 py-2 text-sm"
                                        role="menuitem" tabindex="-1" id="menu-item-2">Our leadership</RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div @mouseleave="showOurMinistriesDropDown = false" @mouseenter="showOurMinistriesDropDown = true">
                        <div class="hover:cursor-pointer">
                            Our ministries
                            <i class="fa-solid fa-chevron-down"></i>
                        </div>
                        <div v-if="showOurMinistriesDropDown" class="absolute z-10 w-56  focus:outline-none" role="menu"
                            aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                            <div class="mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                <div class="py-1" role="none">
                                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                                    <RouterLink to="/our-ministries/bible-club"
                                    class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                                    id="menu-item-0">Bible club</RouterLink>
                                    <RouterLink to="/our-ministries/school-outreach"
                                        class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                                        id="menu-item-1">School outreach</RouterLink>
                                    <RouterLink to="/our-ministries/missions" class="text-gray-700 block px-4 py-2 text-sm"
                                        role="menuitem" tabindex="-1" id="menu-item-2">Mission</RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div @mouseleave="showReadingCornerDropDown = false" @mouseenter="showReadingCornerDropDown = true">
                        <div class="hover:cursor-pointer">
                            Reading Corner
                            <i class="fa-solid fa-chevron-down"></i>
                        </div>
                        <div v-if="showReadingCornerDropDown" class="absolute z-10 w-56  focus:outline-none" role="menu"
                            aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                            <div class="mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                <div class="py-1" role="none">
                                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700"  -->
                                    <RouterLink to="/devotional"
                                        class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                                        id="menu-item-0">Devotionals</RouterLink>
                                    <RouterLink to="/book"
                                        class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                                        id="menu-item-1">Book</RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <RouterLink to="/events">Events</RouterLink>
                    <RouterLink to="/sermons">Sermons</RouterLink>
                    
                    <RouterLink to="/listen-online">Listen online</RouterLink>
                    <RouterLink to="/give">Give</RouterLink>
                    <RouterLink to="/contact">Contact</RouterLink>
                    <span @click="showSearchModal = true"
                        class="inline-flex items-center border-0 py-1 focus:outline-none text-white rounded text-base ms-4 mt-4 md:mt-0"><i
                            class="fa-solid fa-magnifying-glass hover:cursor-pointer"></i>
                    </span>

                </nav>
            </div>
        </header>

        <!-- Search modal -->
        <Transition>
            <div v-if="showSearchModal" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div class="fixed inset-0 bg-gray-700 bg-opacity-90 transition-opacity"></div>

                <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div
                            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg" style="width: 100%;">
                            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div class="mb-4 text-xl font-semibold">Search sermon</div>
                                <input type="text" v-model="searchQuery"
                                    class="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-orange-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                    placeholder="Search sermon">
                            </div>
                            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button type="button"
                                    class="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" @click="searchSermon">Search</button>
                                <button @click="showSearchModal = false" type="button"
                                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>

        <!-- drawer component sm screen nav -->
        <div id="drawer-right"
            class="drawer-right fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-black text-white w-3/4"
            tabindex="-1" aria-labelledby="drawer-right-label">
            <button type="button" data-drawer-hide="drawer-right" aria-controls="drawer-right"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
                <i class="fa-solid fa-xmark h-6 w-6"></i>
                <span class="sr-only">Close menu</span>
            </button>

            <nav class="flex z-50 mt-5 flex-col text-[17px] gap-y-3 px-4 rounded py-5 h-[95%]">
                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/what-we-believe">What we believe</RouterLink>
                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/who-we-are">Who we are</RouterLink>
                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/our-leadership">Our leadership</RouterLink>

                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/our-ministries/bible-club">Bible club</RouterLink>
                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/our-ministries/school-outreach">School outreach</RouterLink>
                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/our-ministries/missions">Mission</RouterLink>

                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/devotional">Devotionals</RouterLink>
                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/book">Book</RouterLink>
                
                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/sermons">Sermons</RouterLink>
                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/events">Events</RouterLink>
                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/listen-online" class="mr-3">Listen online</RouterLink>
                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/give">Give</RouterLink>
                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/contact">Contact</RouterLink>

                
                <span @click="showSearchModal = true"
                    class="inline-flex items-center border-0 py-1 focus:outline-none text-white rounded text-base mt-4 md:mt-0 hidden lg:block"><i class="fa-solid fa-magnifying-glass hover:cursor-pointer"></i>
                </span>
                <span class="flex items-center lg:hidden">
                    <input type="text" v-model="searchQuery"
                        class="block w-[90%] rounded-md border-0 focus:ring-orange-500 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                        placeholder="Search sermon">
                    
                    <span @click="searchSermon" class="px-3" data-drawer-hide="drawer-right" aria-controls="drawer-right">
                        <i class="fa-solid fa-magnifying-glass w-[100%]"></i>
                    </span>
                </span>

                <RouterLink data-drawer-hide="drawer-right" aria-controls="drawer-right" to="/" class="h-14 md:col-span-4 lg:col-span-2 title-font font-medium text-gray-900 mt-auto">
                    <img src="../assets/images/tsg_logo.png" alt="church_logo" class="inline-flex items-center my-3" />
                </RouterLink>
            </nav>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch} from 'vue';
import { useRoute, useRouter } from 'vue-router';

let lastScrollY = ref(window.screenY)
let navbar = ref(null)

let showOurMinistriesDropDown = ref(false)
let showGetToKnowUsDropDown = ref(false)
let showReadingCornerDropDown = ref(false)

let showSearchModal = ref(false)

window.addEventListener('scroll', () => {
    if (window.scrollY == 0) {
        navbar.value.style.backgroundColor = "transparent"
    } else {
        navbar.value.style.backgroundColor = "black"
    }

    if (lastScrollY.value > window.scrollY) {
        navbar.value.style.top = "0"
    } else if (lastScrollY.value < window.scrollY) {
        navbar.value.style.top = "-80px"
    }

    lastScrollY.value = window.scrollY
})

let searchQuery = ref(null)
let router = useRouter()
let route = useRoute()

function searchSermon() {
    if (!searchQuery.value) {
        return
    }
    router.push(`/sermons?q=${searchQuery.value}`)
    showSearchModal.value = false

    searchQuery.value = null
}
</script>

<style scoped>
header {
    background-color: transparent;
    transition: 0.5s;
}

.exact-active-link,
.active-link {
    color: #f97316;
}

.drawer-right{
    transition: .5s ease-in;
}
</style>