<template>
    <div class="bg-[#EEF1F4]">
        <div class="uppercase text-center font-bold text-lg">Sermons</div>

        <div v-if="initialDataLoad">
            <div v-if="sermons.length > 0" class="grid md:grid-cols-5 md:gap-12 lg:gap-32 mt-10">
                <div class="col-span-5 lg:col-span-3 relative">
                    <RouterLink :to="`sermons/${sermon.id}`" class="h-full w-full" v-for="sermon in sermons.slice(0, 1)">
                        <div>
                            <img :src="sermon.data.cover_image" :alt="`${sermon.data.title} thumbnail`" class="w-full">
                        </div>
                        <div
                            class="absolute grid grid-cols-5 bg-white w-full md:max-w-[70%] lg:max-w-[50%] w-fit p-5 md:top-1 md:left-1">
                            <div class="col-span-5">
                                <div class="text-orange-500">{{ new Date(sermon.data.date_created).toDateString() }}</div>
                                <div class="font-bold">{{ sermon.data.title }}</div>
                            </div>
                            <!-- <div class="col-span-2 flex mt-2 gap-3 justify-end">
                                <div class="px-4 py-[0.6rem] bg-orange-500 rounded-full hover:cursor-pointer h-fit">
                                    <div @click="playTrack" v-if="!audioPlayed">
                                        <i class="fa-solid fa-play fa-2xs"></i>
                                    </div>
                                    <div @click="pauseTrack" v-if="audioPlayed">
                                        <i class="fa-solid fa-pause fa-2xs"></i>
                                    </div>
                                </div>
                                    
                                <div @click="downloadFile(sermon.data.sermons[0].audio_file)" class="px-4 py-[0.6rem] bg-orange-500 rounded-full hover:cursor-pointer h-fit">
                                    <i class="fa-solid fa-download fa-fade fa-2xs"></i>
                                </div>
                            </div> -->
                        </div>
                    </RouterLink>
                </div>
                <div class="col-span-2 hidden lg:block">
                    <div class="rounded bg-white p-3 bg-orange-50">
                        <div>
                            <div class="rounded bg-white p-3 bg-slate-200">
                                <div class="grid grid-cols-2 text-black mb-6">
                                    <div class="font-bold">More sermons</div>
                                    <RouterLink to="/sermons" class="uppercase font-bold flex justify-end">View all</RouterLink>
                                </div>
    
                                <div class="grid grid-cols-1 divide-y divide-orange-500">
                                    <RouterLink :to="`sermons/${sermon.id}`" class="p-2" v-for="(sermon, index) in sermons.slice(1, 5)" :key="index">
                                        <div class="text-orange-500">{{ new Date(sermon.data.date).toDateString() }}</div>
                                        <div class="text-black text-xl">{{ sermon.data.title }}</div>
                                    </RouterLink>
                                </div>
    
                            </div>
                        </div>
    
                    </div>
                </div>
    
                <div class="block lg:hidden mt-28 md:mt-3 col-span-5">
                    <div>
                        <div class="grid grid-cols-2 text-black mb-6">
                            <div class="font-bold">More sermons</div>
                            <RouterLink to="/sermons" class="uppercase font-bold flex justify-end">View all</RouterLink>
                        </div>
                    </div>
                    <div class="flex gap-x-5 overflow-auto">
                        <RouterLink :to="`sermons/${sermon.id}`" class="flex-none" v-for="(sermon, index) in sermons.slice(1, 6)" :key="index">
                            <img :src="sermon.data.cover_image" :alt="`${sermon.data.title} thumbnail`" class="h-24 lg:h-40 w-44 rounded">
                        </RouterLink>
                    </div>
                </div>
            </div>

            <div v-else>
                No sermon found
            </div>
        </div>
        <div v-else>
            <Loader />
        </div>
    </div>
</template>

<script setup>
import { useDataStore } from '../../stores/useData';
import { storeToRefs } from 'pinia'
import Loader from '../Loader.vue';
import { ref } from 'vue'
// import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';

let dataStore = useDataStore()
let { sermons, initialDataLoad } = storeToRefs(dataStore)

// let storage = getStorage()

// const downloadFile = async (audioUrl) => {
//   try {
//     // Replace 'path/to/your/file' with the actual path to your file in Firebase Storage
//     const filePath = 'path/to/your/file';
    
//     const fileRef = storageRef(storage, audioUrl);
//     const downloadUrl = await getDownloadURL(fileRef);

//     // Create a link element to trigger the download
//     const link = document.createElement('a');
//     link.href = downloadUrl;
//     link.download = 'your-file-name'; // Set the desired file name
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   } catch (error) {
//     console.error('Error downloading file:', error.message);
//   }
// }
</script>

<style scoped></style>