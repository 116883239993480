<template>
    <div>
        <div v-if="initialDataLoad" class="">
            <div v-if="events.length > 0" v-for="event in events.slice(0, 1)" class="grid md:grid-cols-2 lg:px-20 flex items-center px-8 md:p-20 py-10">
                <div class="lg:px-32 md:px-10 md:text-start pt-5">
                    <div class="hidden md:block">
                        <div class="uppercase font-extrabold text-lg text-center">Upcoming events</div>
                        <div class="h-[2px] bg-slate-500 w-full my-2"></div>
                    </div>
    
                    <div class="mt-4 flex md:justify-center grid gap-y-5 text-lg">
                        <div class="font-extrabold text-2xl lg:text-4xl">{{ event.data.title }}</div>
                        <div>
                            <div v-if="event.data.date"><i class="fa-regular fa-calendar"></i> {{ event.data.date }}</div>
                            <div v-if="event.data.time"><i class="fa-solid fa-clock"></i> {{ event.data.time }}</div>
                            <div v-if="event.data.venue"><i class="fa-solid fa-location-dot"></i> {{ event.data.venue }}</div>
                        </div>
    
                        <div class="flex gap-3 justify-center md:justify-start text-base">
                            <RouterLink to="/listen-online">
                                <SecondaryButton content="join in" class="text-xs lg:text-base"></SecondaryButton>
                            </RouterLink>
    
                            <RouterLink to="/events">
                                <PrimaryButton content="see all events" class="text-xs lg:text-base"/>
                            </RouterLink>
                        </div>
                    </div>
                </div>
                <div class="order-first md:order-last">
                    <div class="block md:hidden mb-8">
                        <div class="uppercase font-bold text-lg text-center">Upcoming events</div>
                        <div class="h-[2px] bg-slate-500 w-full my-2"></div>
                    </div>
    
                    <img class="md:px-2" :src="event.data.cover_image" :alt="`${event.data.title} thumbnail`">
                </div>
            </div>
        </div>
        <div v-else class="px-8 md:p-20 py-10">
            <Loader />
        </div>
    </div>
</template>

<script setup>
import PrimaryButton from '../PrimaryButton.vue';
import SecondaryButton from '../SecondaryButton.vue';
import { useDataStore } from '../../stores/useData';
import { storeToRefs } from 'pinia'
import Loader from '../Loader.vue';

let dataStore = useDataStore()
let { events, initialDataLoad } = storeToRefs(dataStore)
</script>

<style lang="scss" scoped>

</style>