<template>
    <div class="p-8 lg:p-20">

        <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <RouterLink :to="`/who-we-are`" class="h-44 bg-slate-200 relative who-we-are">
                <div class="uppercase text-white font-bold text-3xl text-center flex justify-center items-center h-full relative">who we are</div>
            </RouterLink>

            <RouterLink :to="`/our-leadership`" class="h-44 bg-slate-200 relative bg-cover bg-center our-leadership">
                <div class="uppercase text-white font-bold text-3xl text-center flex justify-center items-center h-full">our leadership</div>
            </RouterLink>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>
.who-we-are {
    background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)), url('../../assets/images/brethren_hero.png');
    background-position: center;
    background-size: cover;
}

.our-leadership {
    background: linear-gradient(rgb(0, 0, 0, 0.5), rgb(0, 0, 0, 0.5)), url('../../assets/images/our_leadership.jpg');
    background-position: center;
    background-size: cover;
}
</style>