<template>
    <div class="my-6">
        <div class="flex justify-center uppercase font-bold text-xl">Find us</div>

        <!-- make accordion for sm screen -->
        <div class="grid grid-cols-2 mx-10 mx-8 lg:mx-80">
            <div class="flex flex-col gap-y-3 p-4 break-words">
                <div class="uppercase text-xl md:text-2xl font-bold hover:cursor-pointer" :class="{'text-orange-500': branch == 'ogui', 'text-black': branch != 'ogui' }" ref="ogui" @click="changeAddress('ogui')">ogui</div>
                <div class="uppercase text-xl md:text-2xl font-bold hover:cursor-pointer" :class="{'text-orange-500': branch == 'wtc', 'text-black': branch != 'wtc' }" ref="wtc" @click="changeAddress('wtc')">w.t.c.</div>
                <div class="uppercase text-xl md:text-2xl font-bold hover:cursor-pointer" :class="{'text-orange-500': branch == 'unec', 'text-black': branch != 'unec' }" ref="unec" @click="changeAddress('unec')">u.n.e.c.</div>
                <div class="uppercase text-xl md:text-2xl font-bold hover:cursor-pointer" :class="{'text-orange-500': branch == 'old unth', 'text-black': branch != 'old unth' }" ref="old_unth" @click="changeAddress('old unth')">old u.n.t.h.</div>
                <div class="uppercase text-xl md:text-2xl font-bold hover:cursor-pointer" :class="{'text-orange-500': branch == 'agbani', 'text-black': branch != 'agbani' }" ref="agbani" @click="changeAddress('agbani')">abgani</div>
                <div class="uppercase text-xl md:text-2xl font-bold hover:cursor-pointer" :class="{'text-orange-500': branch == 'nsukka', 'text-black': branch != 'nsukka' }" ref="nsukka" @click="changeAddress('nsukka')">nsukka</div>
                <div class="uppercase text-xl md:text-2xl font-bold hover:cursor-pointer" :class="{'text-orange-500': branch == 'monarch', 'text-black': branch != 'nsukka' }" ref="nsukka" @click="changeAddress('monarch')">monarch</div>
            </div>

            <div class="text-lg p-4 break-words">
                <div class="font-bold text-base my-1">Address</div>
                <div class="my-2">{{ address }}</div>
                <div class="my-2">{{ phone }}</div>
                <div class="my-2">{{ email }}</div>

                <div class="flex justify-center my-8 hidden md:flex" v-if="mapUrl">
                    <iframe :src="mapUrl" width="800" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            
        </div>

        <div class="flex justify-center m-8 block md:hidden" v-if="mapUrl">
            <iframe :src="mapUrl" width="800" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

let address = ref('Mater Purisima Event Center, China Town, Opposite 4 market days, Ogui road')
let phone = ref('123456789')
let email = ref('info@thesaintsgathering.org')
let mapUrl = ref('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.5327554585624!2d7.504659676738517!3d6.453971300628593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1044a3ebea7a899f%3A0x7ab12700f9bd8a3b!2sMater%20Purisima%20Event%20Centre!5e0!3m2!1sen!2sng!4v1702475963459!5m2!1sen!2sng')
let branch = ref('ogui')

function changeAddress(selectedBranch) {
    if(selectedBranch == 'ogui') {
        address.value = 'Mater Purisima Event Center, China Town, Opposite 4 market days, Ogui road'
        phone.value = '+234 814 321 1989'
        email.value = 'info@thesaintsgathering.org'
        mapUrl.value = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.5327554585624!2d7.504659676738517!3d6.453971300628593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1044a3ebea7a899f%3A0x7ab12700f9bd8a3b!2sMater%20Purisima%20Event%20Centre!5e0!3m2!1sen!2sng!4v1702475963459!5m2!1sen!2sng'
        branch.value = 'ogui'
    }

    if(selectedBranch == 'wtc') {
        address.value = 'No. 4 Osadebe Street, by WTC Bustop, Opposite Tickles Bush Bar, Ogui New Layout Enugu'
        phone.value = '+234 805 038 4088'
        email.value = 'info@thesaintsgathering.org'
        mapUrl.value = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.714972167944!2d7.496557874885973!3d6.4306499242355954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1044a166f28089f9%3A0x6365751262c81d8d!2s16%20Osadebe%20St%2C%20Achara%2C%20Enugu%20401105%2C%20Enugu!5e0!3m2!1sen!2sng!4v1705874051676!5m2!1sen!2sng'
        branch.value = 'wtc'
    }

    if(selectedBranch == 'unec') {
        address.value = 'Faculty of Business Administration (FBA)  Hall 1, University of Nigeria, Enugu Campus'
        phone.value = '+234 703 775 0198'
        email.value = 'info@thesaintsgathering.org'
        mapUrl.value = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.8300626148894!2d7.503476437532152!3d6.4275997020202515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1044a15cd4d6c639%3A0x2d50a23fda276d96!2sFBA%20Auditorium!5e0!3m2!1sen!2sng!4v1705874173413!5m2!1sen!2sng'
        branch.value = 'unec'
    }

    if(selectedBranch == 'old unth') {
        address.value = 'Braintrust Academy, behind School of Nursing Lecture block, Old UNTH, Enugu'
        phone.value = '+234 817 926 4538'
        email.value = 'info@thesaintsgathering.org'
        mapUrl.value = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7560.912985891943!2d7.4755405832276915!3d6.436765092430584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1044a10f1d4943a7%3A0xf4796be2cb60b8e3!2sBRAIN%20TRUST%20ACADEMY!5e0!3m2!1sen!2sng!4v1705874269746!5m2!1sen!2sng'
        branch.value = 'old unth'
    }

    if(selectedBranch == 'agbani') {
        address.value = 'Obe Women\'s hall, by Obe Girls Bustop, Agbani, Enugu'
        phone.value = '+234 810 005 4975'
        email.value = 'info@thesaintsgathering.org'
        mapUrl.value = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126889.0143201223!2d7.431236763653705!3d6.3575719544515605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10435ed674426c61%3A0x827e9f9c47370ad8!2sTHE%20SAINTS%20GATHERING%20CHURCH%20AGBANI!5e0!3m2!1sen!2sng!4v1709284529227!5m2!1sen!2sng'
        branch.value = 'agbani'
    }
    
    if(selectedBranch == 'nsukka') {
        address.value = 'Old Agric Hall, beside the Dept. of Agricultural Extension, along the road across Awolowo hostel, UNN'
        phone.value = '+234 706 517 3550'
        email.value = 'info@thesaintsgathering.org'
        mapUrl.value = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.2152351622376!2d7.4082103783950295!3d6.864790801422988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1044e7df6d77e2b5%3A0xbf28a868e3d66888!2sOLD%20AGRIC%20HALL!5e0!3m2!1sen!2sng!4v1705874467237!5m2!1sen!2sng'
        branch.value = 'nsukka'
    }

    if(selectedBranch == 'monarch') {
        address.value = 'No. 7 Divine drive, opposite Zeta Academy, Monarch Avenue, Enugu'
        phone.value = '+234 805 515 5844'
        email.value = 'info@thesaintsgathering.org'
        mapUrl.value = 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3964.853040772067!2d7.5223185749920045!3d6.412922693577891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNsKwMjQnNDYuNSJOIDfCsDMxJzI5LjYiRQ!5e0!3m2!1sen!2sng!4v1708864770494!5m2!1sen!2sng'
        branch.value = 'monarch'
    }
}

function activeColor(){
    if (condition) {
        
    }
}
</script>

<style scoped>

</style>